import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import getOnlyPublished from '../utils/functions';
import {
  SiteSiteMetadata,
  MarkdownRemarkConnection,
} from '../../types/graphql-types';

interface Props {
  data: {
    site: {
      siteMetadata: SiteSiteMetadata;
    };
    allMarkdownRemark: MarkdownRemarkConnection;
  };
  location: Location;
}

const Tags: React.FC<Props> = ({ data, location }: Props) => {
  const siteTitle = data.site.siteMetadata.title;
  const allPosts = data.allMarkdownRemark.edges;
  const posts =
    process.env.NODE_ENV === 'production'
      ? getOnlyPublished(allPosts)
      : allPosts;

  let tags: string[] = [];
  posts.forEach(post => {
    if (post.node.frontmatter.tags) {
      tags = tags.concat(post.node.frontmatter.tags);
    }
  });
  const uniqTags = [...new Set(tags)];

  const tagCounts = posts
    .flatMap(post => post.node.frontmatter.tags)
    .reduce((counts: { [key: string]: number }, key: string) => {
      const tmpCounts = counts;
      tmpCounts[key] = counts[key] ? counts[key] + 1 : 1;
      return counts;
    }, {});

  return (
    <Layout location={location} title={siteTitle}>
      {uniqTags.length > 0 && (
        <ul className="tags">
          {uniqTags.map(tag => (
            <li className="post" key={tag}>
              <Link to={`/tags/${tag}`}>#{tag}</Link> ({tagCounts[tag]})
            </li>
          ))}
        </ul>
      )}
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query Tags {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2000
    ) {
      edges {
        node {
          frontmatter {
            tags
            published
          }
        }
      }
    }
  }
`;
